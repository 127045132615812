.before-after-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    height: 100%;
    width: 100%;
}

.gallery-title {
    margin-top: 100px;
    text-align: center;
    font-size: 1.5em;
}

.slider-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.images {
    width: 32vw;
    height: 32vw;
    margin: 0 20px;
    object-fit: cover;
    object-position: center;
    border: 5px solid rgb(218, 165, 32);
    border-radius: 15px;;
    box-shadow: 0 0 10px white;
}

.before-after {
    margin-top: 0;
    font-size: 2vw;
}

.arrows-desktop{
    font-size: 3vw;
    align-self: center;
    cursor: pointer;
}

.arrows-mobile{
    display: none;
}

@media screen and (max-width: 1030px) {
    .before-after-container {
        padding-top: 85px;
    }
    
    .slider-container {
        flex-direction: column;
        width: 80%;
        height: auto;
    }

    .images {
        width: 80%;
        height: 250px;
        object-fit: cover;
        object-position: center;
        border: 2.5px solid rgb(218, 165, 32);
        border-radius: 15px;;
        box-shadow: 0 0 10px white;
    }

    .before-after {
        margin: 3px 0 10px 0;
        font-size: 4vw;
    }

    .arrows-desktop{
        display: none;
    }

    .arrows-mobile{
        display: block;
        font-size: 30px;   
        font-weight: bold; 
        align-self: center;
        cursor: pointer;
    }
}