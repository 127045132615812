
.fade{
    animation: fadeIn 2.5s;
    -webkit-animation: fadeIn 2.5s;
    -moz-animation: fadeIn 2.5s;
    -o-animation: fadeIn 2.5s;
    -ms-animation: fadeIn 2.5s;
}
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .hide{
    display: none;
  }


