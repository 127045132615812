.showcase-carousel{
    width: 100vw;
    height: 100vh;
  }

  .bg-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    
    .nav-arrows-container{
      position: relative;
      bottom: 25%;
      display: flex;
      justify-content: space-around;
      color: rgb(171, 171, 171);
      font-size: 45px;
    }
    
    .arrows{
      cursor: pointer;
      font-size: 60px;
      transition: filter 200ms;
      color: rgba(214, 45, 45, 0.8)
    }
    
    .arrows:hover{
      color: #fff;
    }
    
    @media screen and (max-width: 1120px) {
        .showcase-carousel{
          width: 100vw;
          height: 100vh;
          background: inherit;
        }

       .nav-arrows-container{
        position: relative;
        bottom: 200px;
        display: flex;
        justify-content:space-between;
      }

      .arrows{
        font-size: 40px;
        padding: 0 40px;
        font-weight: bold;
      }

      .arrows:hover{
        color:rgba(214, 45, 45, 0.8)
      }

    }