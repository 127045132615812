.rate-card {
    width: 225px;
    height: 360px;
    background-color: white;
    border-radius: 5%;
    color: black;
    font-family: serif;
}

.rate-card:hover{
    filter: drop-shadow(0px 0px 20px rgb(0, 0, 0));
    transform: scale(130%);
    transition: all 400ms;
}
.content{
    position: relative;
    top: -35px;
}

.rate-name{
    font-size: 18px;
    margin-bottom: 0;
}

.sub-name{
    font-size:12px;
    margin-top: 0;
    margin-bottom: 0;
}

.inner-border {
    box-shadow: inset 0px 0px 0px 1px black;
    border: 10px solid rgb(240, 240, 240);
}

.card-banner{
    position: absolute;
    z-index: 0;
    left: 136px;
    top: 8px;
    border-radius: 2px;
    opacity: .8;
    box-shadow: 0 0 10px black;
    width: 80%;
    height: 40px;
    object-fit: cover;
    z-index: 0;
}

.service-list {
    list-style: none;
    position: relative;
    left: -40px;
    text-align: left;
    width: 225px;
    text-align: center;
}

.service-list li{
    font-size: 12px;
    margin-bottom: 6px;
}

.top-left{
    position: relative;
    left: -95px;
    top: -25px;
    font-size: 1.5em;
}

.bottom-right{
    position: absolute;
    left: 194px;
    top: 242px;
    font-family: serif;
    font-size: 1.5em;
    transform: rotate(180deg);
}

.D{
    margin-bottom: -10px;
    font-size: 1.5em;
}

i {
    font-size: .7em;
}

.quote-link{
    text-decoration: none;
    color: blue;
}
.quote-links:hover{
    color:red;
}

@media screen and (max-width: 1030px) {
    .content{
        top: -45px;
    }

    .top-left{
        position: relative;
        left: -95px;
        top: -28px;
        font-size: 1.5em;
    }

    .bottom-right{
        left: 194px;
        top: 246px;
    }

    .rate-card:hover{
        transform: scale(115%);
    }
}




