.contact-container {
    padding-top:100px;
    height: 100%;
    width: 100%;
}

.contact-title {
    text-align: center;
    font-size: 2vw;
}

.astericks {
    
}

@media screen and (max-width:1225px){
    .contact-container {
        padding-top: 90px;
    }
    .contact-title {
        text-align: center;
        font-size: 3vw;
    }
}

@media screen and (max-width:600px){
    .contact-container {
        padding-top: 60px;
    }
    .contact-title {
        text-align: center;
        font-size: 5vw;
    }
    .astericks {
        font-size: 3vw;
        margin-top: 0;
    }
}