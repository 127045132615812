.about-container {
    display: flex;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    margin-top: 198px;

}

.about-bio {
    position: absolute;
    top: 40vw;
    width: 80%;
    z-index: -10;
    text-align: left;
    height: auto;
    border-radius: 5px;
}

.about-video-container {
    position: fixed;
    width: 100%;
    height: auto;
    top: 0px;
}

.product-video{
    width: 100%;
    height: 40vw;
    object-fit: cover;
    object-position: 0 -225px;
    border: rgb(0, 0, 0) 2px solid;
}
@media screen and (max-width: 1450px){
    .product-video{
        object-position: bottom;
    }
}


@media screen and (max-width: 450px) {
    .about-container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-around;
    }
    
    .about-video-container {
        position: fixed;
        top: 63px;
        height: auto;
        border-radius: 5px;
    }

    .about-bio {
        position: relative;
        width: 90%;
        padding-left: 10px;
        top: 10px;
        z-index: -10;
        text-align: center;
        height: auto;
    }
    
    .product-video{
        width: 100%;
        height: auto;
        border: rgb(0, 0, 0) 2px solid;
    }
}