.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submitted-message {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    background-color: rgba(214, 45, 45, 0.8);
    margin-bottom: 15px;
    width: 800px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 2vw;
    border: white solid 1px;
    border-radius: 5px;
    color: white;
    box-shadow: 0 0 8px rgb(218, 165, 32);
    text-align: center;
}

.input::placeholder{
    color: rgba(255, 255, 255, 0.766);
}

.care {
    margin-top: 0;
    font-size: 1.5vw;
}

.ext {

}

.int {
    margin-bottom: 15px;
}   

.needs {
    margin-bottom: 5px;
}

.name {
   
}

.email{
    margin-bottom: 0;
    
}

.notes {
    width: 800px;
    
}

.contact-button{
    background: rgba(218, 165, 32, 0.48);
    margin-bottom: 0;
    border-radius: 10px;
    font-size: 1.5vw;
    width: 400px;
    border: rgb(255, 255, 255) 1px solid;
    color: white;
    transition: background-color 400ms;
}
.contact-button:hover {
    background-color: rgb(179, 179, 179);
    color: black;
}

.thank-you {
    font-size: 3vw;
}

.talk-soon {
    font-size: 1.5vw;
}


@media screen and (max-width:1225px){
    .input {
        font-size: 2vw;
        border: white solid 1px;
        color: white;
        width: 500px;
        text-align: center;
    }

    .contact-button{
        width: 200px;
        border: rgb(255, 255, 255) 1px solid;
        color: white;
        transition: background-color 400ms;
    }
}



@media screen and (max-width:500px){
    .input-field {
        margin-bottom: 8%;
    }
    
    .input {
        font-size: 4vw;
        width: 225px;
    }

    .email-message {
        font-size: 3vw;
    }

    .contact-button {
        font-size: 3vw;
        width: 80px;
    }

    .thank-you {
        font-size: 6vw;
    }

    .talk-soon {
        font-size: 4vw;
    }

    .care {
        margin-top: 0;
        font-size: 3vw;
    }

    .notes {
        width: 225px;
        height: 80px;
    }
}