.navbar {
    position: fixed;
    height: 100px;
    width: 100%;
    border-bottom: rgb(218, 165, 32) 2px solid;
    background-color: rgba(214, 45, 45, 0.8);
    display: flex;
    align-items: center;
    z-index: 9999;
}

.nav-logo {
    position: absolute;
    left: 25px;
    top: 8px;
    width: 90px;
    align-self: baseline;
    border-radius: 10px;
    transform: rotate(-10deg);
    z-index: 100;
    transition: all 0.25s ease;
}

.nav-logo:hover {
    transform:rotate(.10deg);
    filter: brightness(1.5);
}

.title {
    color: white;
    position: absolute;
    left: 130px;
    font-size: 3em;
    top: 10px;
    transition: all 0.25s ease;
    
}

.sub-title {
    position: absolute;
    color: rgb(218, 165, 32);
    left: 140px;
    top: 60px;
    font-size: 1.5em;
    transition: all 0.25s ease;
}

.nav-menu {
    display: flex;
    justify-content: center;
    position: fixed;
    right: 200px;
}

.nav-item {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    margin: 0 10%;
    font-size: 1.5em;
    z-index: 1;
}

.nav-links {
    color: white;
    text-decoration: none;
    transition: color 400ms;
}

.nav-links:hover {
    color: rgb(218, 165, 32);
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 1225px) {
    .navbar {
        height: 65px;
    }
    
    .menu-icon {
        display: flex;
        flex-direction: column;
        position: absolute;
        color: rgb(218, 165, 32);
        top: 45px;
        right: 15px;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu-dashes {
        margin: -14px 0 -14px 0;
        transition: all 100ms;
    }
    
    .menu-dashes-angle1{
        position: absolute;
        transform: rotate(225deg);
        top: -5px;
        right: 0px;
        margin: -20px 0 -20px 0;
        transition: all 250ms;
    }
    
    .menu-dashes-angle2{
        position: absolute;
        transform: rotate(-225deg);
        top: -5px;
        right: 0px;
        transition: all 250ms;
        margin: -20px 0 -20px 0;
        filter: opacity(.6);
    }

    .nav-menu {
        height: 0px;
        position: absolute;
        top: 70px;
        left: 0px;
        overflow: hidden;
    }

    .nav-menu.active {
        height: 145px;
        width: 100%;
        padding-top: 10px;
        background-color: rgb(214, 45, 45, .8);
        top: 49px;
        left: 0px;
        border-bottom: rgb(218, 165, 32) 2px solid;
        transition: height 250ms ease;
    }

    .nav-logo {
        width: 60px;
        left: 20px;
        border-radius: 5px;
    }

    .nav-logo:hover {
        filter: brightness(1);
        transform: rotate(-10deg);
    }

    .logo-spin {
        transform: rotate(-450deg);
        transition: all 0.5s ease;
        top: -10px;
        left: 25px;
    }

    .title {
        font-size: 1.8em;
        left: 85px;
        top: 10px;
    }

    .sub-title {
        top: 39px;
        font-size: 1em;
        left: 90px;
    }

    .title-nudge {
        left: 110px;
        transition: all 0.25s ease;
    }

    .nav-links {
        display: flex;
        flex-direction: column;
        font-size: 1em;
        margin-left: -25px;
    }

    .nav-item {
        margin-left: 0;
        margin-top: 3px;
        font-size: 1.5em;
        padding-bottom: 5px;
    }

    .active {
        display: flex;
        flex-direction: column;
        border-bottom: none;
    }

    .hide {
        display: none;
    }
}