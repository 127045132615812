.services-container {
    position: absolute;
    text-align: center;
    height: 100%;
    width: 100%;
    z-index: -1;
    padding-top: 80px;
}

.services-title {
    color: rgb(255, 255, 255);
    font-size: 2vw;
    text-align: center;
}

.cards{
    top: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card{
    margin: 5%;
}

.card-hide{
    position: absolute;
    top: -400px;
}

.card-one{
    position: absolute;
    left: 20%;
    top: 20%;
    transform: rotate(345deg);
    transition: all ease-in 400ms;
    filter: brightness(.85);
}
.card-one:hover{
    transform: rotate(360deg);
    z-index: 100;
    transition: all 200ms;
    filter: brightness(1);
}

.card-two{
    position: absolute;
    left: 35%;
    top: 28%;
    transform: rotate(365deg);
    transition: all ease-in 350ms;
    filter: brightness(.85);
}
.card-two:hover{
    transform: rotate(360deg);
    z-index: 100;
    transition: all 200ms;
    filter: brightness(1);
}
.card-three{
    position: absolute;
    left: 50%;
    top: 22%;
    transform: rotate(358deg);
    transition: all ease-in 375ms;
    filter: brightness(.85);
}
.card-three:hover{
    transform: rotate(360deg);
    z-index: 100;
    transition: all 200ms;
    filter: brightness(1);
}
.card-four{
    position: absolute;
    left: 65%;
    top: 28%;
    transform: rotate(345deg);
    transition: all ease-in 400ms;
    filter: brightness(.85);
}
.card-four:hover{
    transform: rotate(360deg);
    z-index: 100;
    transition: all 200ms;
    filter: brightness(1);
}


@media screen and (max-width: 1030px) {
    .services-container {
        margin-top: 25px;
    }
    
    .services-title {
        font-size: 3vw;
        text-align: center;
    }
    .cards{
        top: 20%;
        flex-direction: column;
        align-items: center;
    }

    .card-one{
        top: 10%;
        left: 25%;
    }

    .card-two{
        top: 28%;
        left: 20%;
    }

    .card-three{
        top: 50%;
        left: 32%;
    }

    .card-four{
        top: 75%;
        left: 20%;
        transform: rotate(380deg)
    }
}